import React, { Component } from "react";
import { navigate } from "gatsby-link";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { SubscribeForm } from "../common/subscribeForm";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    // eslint-disable-next-line
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      // eslint-disable-next-line
      .catch((error) => alert(error));
  };

  render() {
    return (
      <div className="container">
        <form
          name="contact"
          method="post"
          action="/contact/success"
          encType="application/x-www-form-urlencoded"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Full Name"
                name="name"
                id="name"
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                type="email"
                placeholder="Email"
                name="email"
                id="email"
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Message</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Message"
                name="message"
                id="message"
                rows="6"
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="field is-grouped is-pulled-right">
            <div className="control">
              <button className="button is-text" type="reset">
                Cancel
              </button>
            </div>
            <div className="control">
              <button
                className="button is-primary"
                type="submit"
                disabled={
                  !this.state.name || !this.state.email || !this.state.message
                }
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

class ContactPageTemplate extends Component {
  render() {
    const { title, subtitle, meta_title, meta_description } = this.props;
    return (
      <div>
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
          <link
            rel="canonical"
            href="https://www.iteachrecruiters.com/contact"
          />
        </Helmet>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title has-text-white is-size-1">{title}</h1>
                    <h2 className="description has-text-white is-size-3">
                      {subtitle}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section main-section-wrap">
          <div className="columns">
            <div className="column is-5 is-offset-1">
              <h2 className="is-size-3 has-text-info">Book a call</h2>
              <div style={{ marginBottom: "3rem" }}>
                <p>
                  If you would like to book a call with me to discuss the course
                  including bulk pricing or anything else, please use the link
                  below.
                </p>

                <a
                  href="https://tidycal.com/aaron.b.decker/iteach-recruiters-15-minute-discovery-call"
                  className="button is-info"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginTop: "1rem" }}
                >
                  Book a call with me to explore options
                </a>
              </div>

              <h2 className="is-size-3 has-text-info">Send a message now</h2>
              <ContactForm {...this.props} />
            </div>
            <div className="column is-5 is-offset-1">
              <h2 className="is-size-3 has-text-info">
                Other ways to get in touch:
              </h2>
              <br />
              <p>
                For any questions related to ITeachRecruiters please don&apos;t
                hesitate to drop me an email. You can reach me directly at{" "}
                <a href="mailto:aaron@ard.ninja">aaron@ard.ninja</a>
                {", "}
                <a
                  href="https://www.linkedin.com/in/aaron-r-decker/"
                  target="_black"
                  rel="noopen noreferrer"
                >
                  Linkedin
                </a>
                , or Twitter{" "}
                <a
                  href="https://twitter.com/dynamicdoublea"
                  target="_black"
                  rel="noopen noreferrer"
                >
                  @dynamicdoublea
                </a>
                . You can also you the form on this page to send a message too.
                I try to answer everything in a timely manner!
              </p>
              <br />
              <p>
                Of course, the best way to get notified with updates about the
                course is to join the waitlist and I will keep you up to date
                via email.
              </p>

              <SubscribeForm />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
